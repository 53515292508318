<template>
  <div class="boost-start-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item><i class="icon-home"></i>促进服务管理</Breadcrumb-item>
        <Breadcrumb-item>开始促进</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="card-container">
        <Card>
          <p slot="title">基本信息</p>
          <div class="card-box clearfix">
            <Row>
              <Col :lg="{span: 2}" :md="{span: 4}" class="tac">
                <div class="card-avatar">
                  <div class="card-img">
                    <img :src="avater" alt="" width="80px" height="80px">
                  </div>
                  <p class="card-text">头像</p>
                </div>
              </Col>
              <Col :lg="{span: 22}" :md="{span: 20}">
                <div class="card-info">
                  <Form :label-width="85">
                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>身份证号：{{id_card}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>姓名：{{name}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>性别：{{sex | sex}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>民族：{{nation}}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>生日：{{birthday}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>年龄：{{age}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>建档日期：{{create_time}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>服务员工：{{staff}}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>联系电话：{{phone}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>方案名称：<a :href="plan_url" target="_blank" class="clicka">{{plan_name}}</a></p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>方案状态：{{plan_status_string}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>促进师：{{booster}}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p><a href="javascript:;" class="clicka" @click="golast()">查看上次促进记录</a></p>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
      <div class="commom-panel-one">
        <Form class="search-form" :label-width="111">
          <Row>
            <Col span="5">
              <Form-item label="上次促进时间：">
                {{last_time}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="沟通时长：">
                <Input v-model="doneForm.continued_time" placeholder="请输入..."></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="下次促进时间：">
                <Date-picker @on-change="changeTime" :options="options" placeholder="选择日期" :clearable="false"></Date-picker>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
            <template>
              <Form :label-width="60" style="display:inline-block; width:300px;" >
                <Form-item label="备注：">
                  <a style="color: #00cb84" @click="goSee()">查看</a>
                </Form-item>
              </Form>
            </template>
          </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="促进资料：">
                <Upload 
                  ref="upload"
                  :show-upload-list="false"
                  :on-success="handleSuccess"
                  :format="['doc','docx','pdf']"
                  :on-format-error="handleFormatError"
                  :on-progress = "progress"
                  action="/api/boost/uploadfile"
                  style="display:inline-block; width:300px;">
                  <a href="javascript:;" class="btn-upload">上传文件</a>
                </Upload>
                <div class="upload-list" v-for="item in uploadList" :key="item.name">
                  <template v-if="item.status === 'finished'">
                    {{item.name}}
                    <div class="upload-list-cover">
                      <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
                    </div>
                  </template>
                  <template v-else>
                    <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
                  </template>
                </div>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="沟通内容：">
                <Input v-model="doneForm.content" type="textarea" :rows="4" placeholder="请输入沟通内容"></Input>
              </Form-item>
            </Col>
          </Row>
        </Form>
      </div>
      <div class="commom-panel-two">
        <div class="commom-panel-title">问题</div>
        <div class="commom-panel-main" v-for='(item, index) in doneProblem' :key="index">
          <Form class="search-form" :label-width="111">
            <Row>
              <Col span="24">
                <Form-item label="咨询种类：">
                  <Cascader :data="problem" style="width: 230px;" v-model="item.idd" change-on-select></Cascader>
                </Form-item>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <Form-item label="详细情况：">
                  <Input v-model="item.detail_info" type="textarea" :rows="4" placeholder="请输入详细情况"></Input>
                </Form-item>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <Form-item label="是否上报解决：">
                  <Select v-model="item.should_report" placeholder="请选择" style="width: 230px;">
                    <Option :value="v.id" v-for="v in shouldList" :key="v.id">{{v.name}}</Option>
                  </Select>
                </Form-item>
              </Col>
            </Row>
            <Row>
            <Col span="24">
              <Form-item label="解决方案" v-if="item.should_report === '0'">
                <Input v-model="item.reslove" type="textarea" :rows="4" placeholder="请输入解决方案"></Input>
              </Form-item>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <Form-item label="">
                  <Button @click="removePro(index)">删除</Button>
                </Form-item>
              </Col>
            </Row>
          </Form>
        </div>
        <div class="add-problem">
          <a href="javascript:;" class="add-btn" @click="newProblem">新建问题单</a>
        </div>
      </div>
      <div class="submit-box tac">
        <Button type="success" size="large" @click="submitDone()">提交</Button>
      </div>
    </div>
    <!--查看方案备注modal-->
    <Modal v-model="addMarkmodal" title="查看备注">
      <Input type="textarea" v-model="add_mark_detail" :autosize="{minRows: 5, maxRows: 10}"></Input>
      <div slot="footer" class="tac">
        <i-button v-on:click="ok_method">保存</i-button>
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import boostService from '@/services/boostService';
import { sex } from '@/filters/filters';
export default {
	components: {
		'v-title': vTitle,
	},
	filters: {
		sex,
	},
	data() {
		return {
			doneForm: {
				member_id: '',
				next_time: '',
				continued_time: '',
				file_ids: '',
				content: '',
				data_string: '',
			},
			add_mark_detail: '',
			addMarkmodal: false,
			doneProblem: [],
			avater: '',
			id_card: '',
			name: '',
			sex: '',
			nation: '',
			birthday: '',
			age: '',
			create_time: '',
			staff: '',
			phone: '',
			plan_name: '',
			plan_status_string: '',
			plan_url: '',
			booster: '',
			last_boost_id: '',
			last_time: '',
			uploadList: [],
			options: {
				disabledDate(date) {
					return date && date.valueOf() < Date.now() - 86400000;
				},
			},
			problem: [],
			shouldList: [
				{
					id: '0',
					name: '已解决',
				},
				{
					id: '1',
					name: '上报',
				},
			],
			uploadPercent: 0,
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	created() {
		var param = { member_id: this.memberId };
		boostService.getStart(param).then((data) => {
			this.avater = data.head_info.user_info.avater;
			this.id_card = data.head_info.user_info.id_card;
			this.name = data.head_info.user_info.name;
			this.sex = data.head_info.user_info.sex;
			this.nation = data.head_info.user_info.nation;
			this.birthday = data.head_info.user_info.birthday;
			this.age = data.head_info.user_info.age;
			this.create_time = data.head_info.user_info.create_time;
			this.staff = data.head_info.user_info.staff;
			this.phone = data.head_info.user_info.phone;
			this.plan_name = data.head_info.plan.plan_name;
			this.plan_status_string = data.head_info.plan.plan_status_string;
			this.plan_url = data.head_info.plan.plan_url;
			this.booster = data.head_info.user_info.booster;
			this.last_boost_id = data.last_boost_id;
			this.doneForm.member_id = data.head_info.user_info.member_id;
			this.last_time = data.last_time;
			this.problem = data.problem_list;
			this.add_mark_detail = data.add_mark_detail;
		});
	},
	mounted() {
		this.uploadList = this.$refs.upload.fileList;
	},
	methods: {
		goSee() {
			this.addMarkmodal = true;
		},
		ok_method() {
			boostService.boostMark({ member_id: this.memberId, mark: this.add_mark_detail }).then(() => {
				this.addMarkmodal = false;
			});
		},
		okCancel() {
			this.addMarkmodal = false;
		},
		golast() {
			if (!this.last_boost_id) {
				this.$Message.warning('不存在上次促进记录');
			} else {
				this.$router.push({ name: 'boostDetail', params: { boost_id: this.last_boost_id } });
			}
		},
		changeTime(date) {
			this.doneForm.next_time = date;
		},
		handleSuccess(res, file, fileList) {
			this.uploadList = fileList;
			let arrId = [];
			for (var i = 0; i < fileList.length; i++) {
				arrId.push(fileList[i].response.file_id);
			}
			this.doneForm.file_ids = arrId.join(',');
			// console.log(this.doneForm.files_id)
		},
		handleRemove(file) {
			// 从 upload 实例删除数据
			const fileList = this.$refs.upload.fileList;
			this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
			let arrId = [];
			for (var i = 0; i < fileList.length; i++) {
				arrId.push(fileList[i].response.file_id);
			}
			this.doneForm.file_ids = arrId.join(',');
			// console.log(this.doneForm.files_id)
		},
		handleFormatError(file) {
			this.$Message.warning(
				'文件 ' + file.name + ' 格式不正确，请上传 doc、docx 或 pdf 格式的文件。',
				2,
			);
		},
		progress(event) {
			this.uploadPercent = event.percent;
		},
		submitDone() {
			if (this.validateFormData()) {
				for (var i = 0; i < this.doneProblem.length; i++) {
					this.doneProblem[i].member_id = this.memberId;
					this.doneProblem[i].level1_id = this.doneProblem[i].idd[0]
						? this.doneProblem[i].idd[0]
						: '';
					this.doneProblem[i].level2_id = this.doneProblem[i].idd[1]
						? this.doneProblem[i].idd[1]
						: '';
					delete this.doneProblem[i].idd;
					if (this.doneProblem[i].should_report === '1') {
						this.doneProblem[i].reslove = '';
					}
				}
				this.doneForm.data_string = JSON.stringify(this.doneProblem);
				boostService.boostDone(this.doneForm).then(() => {
					this.$router.push({ path: '/base/boost' });
				});
			}
		},
		validateFormData() {
			if (!this.doneForm.next_time) {
				this.$Message.warning('请填写下次促进时间');
				return false;
			}

			if (this.doneForm.continued_time.length > 30) {
				this.$Message.warning('沟通时长太长，请检查');
				return false;
			}
			if (this.doneForm.continued_time.length === 0) {
				this.$Message.warning('请填写沟通时长');
				return false;
			}
			if (this.doneForm.content.length === 0) {
				this.$Message.warning('请填写沟通内容');
				return false;
			}

			for (var i = 0; i < this.doneProblem.length; i++) {
				if (!this.doneProblem[i].idd[0]) {
					this.$Message.warning('请选择各问题单的咨询种类');
					return false;
				}
				if (this.doneProblem[i].detail_info.length === 0) {
					this.$Message.warning('请输入问题详细情况');
					return false;
				}
				if (this.doneProblem[i].should_report === '0' && !this.doneProblem[i].reslove) {
					this.$Message.warning('请填写解决方案');
					return false;
				}
			}
			if (this.uploadPercent < 100 && this.uploadPercent > 0) {
				this.$Message.warning('请等待文件上传成功后再进行操作');
				return false;
			}
			return true;
		},
		newProblem() {
			this.doneProblem.push({
				source: '促进',
				member_id: '',
				level1_id: '',
				level2_id: '',
				detail_info: '',
				should_report: '0',
				reslove: '',
				idd: [],
			});
		},
		removePro(index) {
			this.doneProblem.splice(index, 1);
		},
	},
};
</script>

<style lang="css" scoped>
.boost-start-container .card-container .ivu-card-head > p {
  color: #fff;
}
.boost-start-container .card-container .card-box {
  position: relative;
}
.boost-start-container .card-container .ivu-col {
  margin-bottom: 20px;
}
.boost-start-container .card-container .card-avatar {
  padding: 30px 0;
}
.boost-start-container .card-container .card-img img {
  border-radius: 100%;
}
.boost-start-container .card-container .card-text {
  text-align: center;
  padding-top: 8px;
}
.boost-start-container .card-container .card-info {
  padding-top: 30px;
}
.boost-start-container .card-container .ivu-form-item {
  margin-bottom: 0;
}
.boost-start-container .clicka {
  color: #ff8400;
  text-decoration: underline;
}
.boost-start-container .submit-box {
  margin-top: 30px;
}
.boost-start-container .ivu-btn-success {
  padding: 11px 48px;
  margin: 0 26px;
}
.boost-start-container .add-problem {
  padding: 10px 30px;
}
.boost-start-container .add-problem .add-btn {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding-left: 26px;
  background: url(@/assets/img/icon_add.png) no-repeat left;
}
</style>
